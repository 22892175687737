<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card outlined>
          <v-card-title class="title">
            STOCK REPORT
            <v-spacer></v-spacer>
            <v-download-column-select
              @onDownload="downloadRecord"
              :showPDF="false"
              >Download Stock Record</v-download-column-select
            >
          </v-card-title>
          <v-data-table
            :headers="headers"
            :loading="isloading"
            :items="items"
            hide-default-footer
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td>{{ item.item_name }}</td>
                <td>{{ item.unit }}</td>
                <td>
                  {{ item.o_qty ? parseInt(item.o_qty) : '-' }}
                </td>
                <td>
                  {{ item.o_rate ? item.o_rate : '-' }}
                </td>
                <td>
                  {{
                    item.o_qty * item.o_rate ? item.o_qty * item.o_rate : '-'
                  }}
                </td>
                <td>
                  {{ item.pur_qty ? item.pur_qty : '-' }}
                </td>
                <td>
                  {{ item.pur_avg_price ? item.pur_avg_price : '-' }}
                </td>
                <td>
                  {{
                    item.pur_avg_price * item.pur_qty
                      ? item.pur_avg_price * item.pur_qty
                      : '-'
                  }}
                </td>
                <td>{{ item.sale_qty ? item.sale_qty : '-' }}</td>
                <td>{{ item.sale_avg_price ? item.sale_avg_price : '-' }}</td>
                <td>
                  {{
                    item.sale_qty * item.sale_avg_price
                      ? item.sale_qty * item.sale_avg_price
                      : '-'
                  }}
                </td>
                <td>
                  {{ item.closing_qty ? item.closing_qty : '-' }}
                </td>
                <td>
                  {{ item.closing_price ? item.closing_price : '-' }}
                </td>
                <td>
                  {{ item.closing_amount ? item.closing_amount : '-' }}
                </td>
              </tr>
              <tr
                class="primary"
                style="color: white; font-weight: bold"
                v-if="index === items.length"
              >
                <td></td>
                <td>Grand Total</td>
                <td></td>
                <td></td>
                <td colspan="2">{{ totalOpeningAmount.currency() }}</td>
                <td></td>
                <td colspan="2">{{ totalPurchaseAmount.currency() }}</td>
                <td></td>

                <td colspan="2">{{ totalSaleAmount.currency() }}</td>
                <td></td>
                <td colspan="2">{{ totalClosingAmount.currency() }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import {
  passDataToExternalDomain,
  redirectDownloadUrl,
} from '@/library/helpers';
const NepaliDate = require('nepali-date');
const nd = new NepaliDate();
export default {
  data: () => ({
    items: [],
    isloading: false,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Item', value: 'name', align: 'left' },
      {
        text: 'Unit',
        value: 'unit_name',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Op.Qty',
        value: 'opening_quantity',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Avg.Price',
        value: 'opening_average_price',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Op.Amt.',
        value: 'opening_amount',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Purchase Qty',
        value: 'purchase_quantity',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Avg.Price',
        value: 'purchase_price',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Amt. In',
        value: 'purchase_amount',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Sale Qty',
        value: 'Sale_quantity',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Avg.Price',
        value: 'sale_price',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Amt.Out',
        value: 'sale_amount',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Cl. Qty',
        value: 'closing_quantity',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Price',
        value: 'closing_price',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Cl.Amt.',
        value: 'cl_amount',
        align: 'left',
        sortable: false,
      },
    ],
    totalOpeningAmount: 0,
    totalPurchaseAmount: 0,
    totalSaleAmount: 0,
    totalClosingAmount: 0,
  }),
  computed: {
    ...mapState(['batch']),
  },
  mounted() {
    this.getstockReport();
  },
  methods: {
    getstockReport() {
      this.isLoading = true;
      this.$rest
        .get('api/account/inventory/stock')
        .then(({ data }) => {
          let opening_amount = 0;
          let purchase_amount = 0;
          let sale_amount = 0;
          let closing_amount = 0;
          data.map((res) => {
            opening_amount += res.o_qty * res.o_rate;
            purchase_amount += res.pur_qty * res.pur_avg_price;
            sale_amount += res.sale_qty * res.sale_avg_price;
            closing_amount += res.closing_amount;
          });
          this.items = data;
          this.totalOpeningAmount = opening_amount;
          this.totalPurchaseAmount = purchase_amount;
          this.totalSaleAmount = sale_amount;
          this.totalClosingAmount = closing_amount;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    downloadRecord() {
      passDataToExternalDomain('reporting');
      setTimeout(() => {
        redirectDownloadUrl({
          uri: '/download/account/inventory/stock',
        });
      }, 300);
    },
  },
};
</script>
